<template>
     <div class="w-full flex flex-col justify-start items-center">

        <div class=" flex justify-start items-center w-full">
            <router-link :to="'/scorecard'" tag="button">
                <ChevronDoubleLeftIcon class=" text-warm-gray-500 font-bold h-6 w-6"/>
            </router-link>
        </div>

        <div class="mt-10 w-3/4">
            <h2 class=" font-semibold tracking-wide text-2xl m-2 text-yellow-600 dark:text-yellow-500">{{ openedSubjectInfo && openedSubjectInfo.name }}</h2>

            <table class="min-w-full m-2 mt-5">
                <tr>
                    <td class="whitespace-nowrap w-56 py-1">Total Response</td>
                    <td class=" min-w-full text-warm-gray-500 dark:text-warm-gray-400">{{ examResultComputedData ? examResultComputedData.totalRes : '---' }}</td>
                </tr>
                <tr>
                    <td class="whitespace-nowrap py-1">Ave. Items from App</td>
                    <td class=" min-w-full  text-warm-gray-500 dark:text-warm-gray-400">{{ examResultComputedData ? Math.ceil(examResultComputedData.totalNumItemsFromApp/examResultComputedData.totalRes) : '---' }}</td>
                </tr>
                <tr>
                    <td class="whitespace-nowrap py-1">Ave. Score</td>
                    <td class=" min-w-full  text-warm-gray-500 dark:text-warm-gray-400">{{ examResultComputedData ? (examResultComputedData.totalScore/examResultComputedData.totalRes).toFixed(2) + ' %' : '---' }}</td>
                </tr>
            </table>

            <!-- subtopic votes -->
            <h2 class=" tracking-wider uppercase font-semibold mt-10 ml-2 text-warm-gray-600 dark:text-warm-gray-50">Subtopic Votes</h2>
            <table class="m-2 mt-2 min-w-full">
                <thead>
                    <tr class="border-t border-b border-warm-gray-800 dark:border-warm-gray-50 bg-warm-gray-300 dark:bg-warm-gray-800 text-warm-gray-700 dark:text-warm-gray-100">
                        <th class="text-left w-52 py-2 pl-2">Subtopics</th>
                        <th class="px-10 text-center py-2">Votes</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(subject,i) in subtopicVotes" :key="i" class="border-b border-dashed border-warm-gray-600 text-warm-gray-500 dark:text-warm-gray-400">
                        <td class="whitespace-nowrap pl-2 py-2">{{ subject.subtopicId }}</td>
                        <td class="text-center">{{ subject.vote }}</td>
                    </tr>
                </tbody>
            </table>

            <!-- latest response -->
            <div class="flex justify-between items-center mt-16">
                <h2 class=" tracking-wider uppercase font-semibold ml-2 text-warm-gray-600 dark:text-warm-gray-50">Responses</h2>
                <div>
                    <select v-model="daysAgo" name="date-range" id="date-range" class="rounded-md dark:bg-warm-gray-700 dark:border-0 text-xs">
                        <option value="15">Last prev 15 days</option>
                        <option value="30">Last prev 30 days</option>
                        <option value="90">Last prev 90 days</option>
                        <option value="180">Last prev 180 days</option>
                        <option value="360">Last prev 360 days</option>
                        <option value="3600">All</option>
                    </select>
                </div>
            </div>
            <table class="m-2 mt-2 min-w-full">
                <thead>
                    <tr class="border-t border-b border-warm-gray-800 dark:border-warm-gray-50 bg-warm-gray-300 dark:bg-warm-gray-800 text-warm-gray-700 dark:text-warm-gray-100">
                        <th class="text-left pl-2 py-2">Users</th>
                        <th class="text-center">Score</th>
                        <th class="text-center">Time Taken</th>
                        <th class="text-center">Date Taken</th>
                        <!-- <th class="text-center"># of Items</th> -->
                        <th class="text-center"># of Items from App</th>
                        <th class="text-center"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="examRes in latestExamResponses" class="border-b border-dashed border-warm-gray-600 text-warm-gray-500 dark:text-warm-gray-400" >
                        <td class="pl-2 py-2 text-left">
                            <a :href="'/v2/user/' + examRes.data.userId" target="_blank" class="hover:underline">
                                {{ examRes.data.email }}
                            </a>
                        </td>
                        <td class="text-center" :class="[examRes.data.isPassed ? 'text-green-700':'text-red-700']">
                            <span :class="!examRes.data.isChangePassingScore && 'pr-5'">
                                {{examRes.data.score}}% 
                            </span>
                            <span v-if="examRes.data.isChangePassingScore">🚩</span>
                        </td>
                        <td class="text-center"> {{ getOrdinal(examRes.data.takeCount) }} </td>
                        <td class="text-center">{{ formatDate(examRes.data.examDate.seconds) }}</td>
                        <!-- <td class="text-center"></td> -->
                        <td class="text-center">
                            <span :class="!examRes.data.isChangeItemCount && 'pr-5'">
                                {{ examRes.data.numItemsFromApp }} / {{ examRes.data.numItemsInExam }} 
                            </span>
                            <span v-if="examRes.data.isChangeItemCount">🚩</span>
                        </td>
                        <td class="text-center text-xs flex justify-end items-center py-2 pr-2">
                            <button v-if="examRes.data.addedQuestion.length > 0" @click="openAddedQuestionModal(examRes.data.addedQuestion)" class="border rounded border-indigo-500 px-1 py-0.5 text-indigo-500 mr-2">
                                added {{ examRes.data.addedQuestion.length }} question
                            </button>
                            <button @click="openSubtopicVotesModal(examRes.data.subtopicVotes)" class="border rounded border-yellow-600 px-1 py-0.5 text-yellow-600 mr-2">
                                <!-- examRes.data.subtopicVotes -->
                                votes
                            </button>
                            <button @click="openCommentsModal(examRes.data.comments)" class="border rounded border-yellow-600 px-1 py-0.5 text-yellow-600">
                                comment
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>
        
    </div>


    <!-- Comments Modal -->
    <TransitionRoot as="template" id="comments-modal" :show="isCommentsModalOpen">
        <Dialog as="div" auto-reopen="true" class="fixed z-10 inset-0 overflow-y-auto" @close="closeCommentsModal()">
            <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <DialogOverlay class="fixed inset-0 dark:bg-warm-gray-900 dark:bg-opacity-75 bg-warm-gray-900 bg-opacity-75" />
            </TransitionChild>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                &#8203;
            </span>
            
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <div class="inline-block align-bottom dark:bg-warm-gray-800  bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all w-full sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6">
                <!-- Close Button -->
                <div class="block absolute top-0 right-0 pt-4 pr-4">
                    <button type="button" class="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none" @click="closeCommentsModal()">
                    <span class="sr-only">Close</span>
                    <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                
                
                <div class="relative px-4 sm:px-6 lg:px-8 max-w-4xl mx-auto py-4 mt-4">

                    <!-- <span class="block text-base text-center dark:text-yellow-300 text-yellow-600 font-semibold tracking-wide uppercase mb-10">
                        Subject Name here sd
                    </span> -->

                    <h2 class="mb-6 font-semibold tracking-wider text-xl text-warm-gray-700 dark:text-white">Comments</h2>
                    
                    <p class=" text-warm-gray-500 dark:text-warm-gray-400">{{ openComments }}</p>

                    
                </div>
                </div>
            </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>

    <!-- Added Question Modal -->
    <TransitionRoot as="template" id="add-question-modal" :show="isAddedQuestionModalOpen">
        <Dialog as="div" auto-reopen="true" class="fixed z-10 inset-0 overflow-y-auto" @close="closeAddedQuestionModal()">
            <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <DialogOverlay class="fixed inset-0 dark:bg-warm-gray-900 dark:bg-opacity-75 bg-warm-gray-900 bg-opacity-75" />
            </TransitionChild>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                &#8203;
            </span>
            
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <div class="inline-block align-bottom dark:bg-warm-gray-800  bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all w-full sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6">
                <!-- Close Button -->
                <div class="block absolute top-0 right-0 pt-4 pr-4">
                    <button type="button" class="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none" @click="closeAddedQuestionModal()">
                    <span class="sr-only">Close</span>
                    <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                
                
                <div v-if="openAddedQuestion.length > 0" class="relative px-4 sm:px-6 lg:px-8 max-w-4xl mx-auto py-4 mt-4">

                    <!-- <span class="block text-base text-center dark:text-yellow-300 text-yellow-600 font-semibold tracking-wide uppercase mb-10">
                        Subject Name here sd
                    </span> -->

                    <h2 class="mb-6 font-semibold tracking-wider text-xl text-warm-gray-600 dark:text-white">Added Question {{ openAddedQuestionIndex + 1}}</h2>

                    <div class=" text-warm-gray-500 dark:text-warm-gray-400">
                        
                        <p>
                            {{ openAddedQuestion[openAddedQuestionIndex].question }}
                        </p>

                        <p class="text-warm-gray-600 dark:text-warm-gray-200 font-semibold tracking-wide mt-8">Choices:</p>

                        <ul>
                            <li v-for="choice in openAddedQuestion[openAddedQuestionIndex].choices" class=" bg-warm-gray-300 dark:bg-warm-gray-900 bg-opacity-30 border border-dashed border-warm-gray-700 my-2 p-2 rounded">
                                {{ choice }}
                            </li>
                        </ul>

                        <div class="flex justify-end mt-8 gap-2">
                            <button @click="openAddedQuestionIndex--" class=" py-1 px-3 border rounded border-warm-gray-500 text-warm-gray-500 flex justify-center items-center disabled:opacity-30" :disabled="openAddedQuestionIndex == 0">
                                <ChevronLeftIcon class=" h-5 w-5"/>
                                <span class="mb-0.5">Prev</span>
                            </button>
                            <button @click="openAddedQuestionIndex++" class=" py-1 px-3 border rounded border-warm-gray-500 text-warm-gray-500 flex justify-center items-center disabled:opacity-30" :disabled="openAddedQuestionIndex == (openAddedQuestion.length - 1)">
                                <span class="mb-0.5">Next</span>
                                <ChevronRightIcon class=" h-5 w-5"/>
                            </button>
                        </div>
                    </div>
                    
                    
                </div>
                </div>
            </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>

    <!-- View Subtopic Votes Modal -->
    <TransitionRoot as="template" id="subtopic-votes-modal" :show="isSubtopicVotesModalOpen">
        <Dialog as="div" auto-reopen="true" class="fixed z-10 inset-0 overflow-y-auto" @close="closeSubtopicVotesModal()">
            <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <DialogOverlay class="fixed inset-0 dark:bg-warm-gray-900 dark:bg-opacity-75 bg-warm-gray-900 bg-opacity-75" />
            </TransitionChild>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                &#8203;
            </span>
            
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <div class="inline-block align-bottom dark:bg-warm-gray-800  bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all w-full sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6">
                <!-- Close Button -->
                <div class="block absolute top-0 right-0 pt-4 pr-4">
                    <button type="button" class="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none" @click="closeSubtopicVotesModal()">
                    <span class="sr-only">Close</span>
                    <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                
                
                <div class="relative px-4 sm:px-6 lg:px-8 max-w-4xl mx-auto py-4 mt-4">

                    <h2 class="font-semibold tracking-wider text-xl text-warm-gray-700 dark:text-white">Votes</h2>
                    <p class="text-warm-gray-300">Below are the subtopic that the user voted for this subject.</p>

                    <table class="mt-8 min-w-full">
                        <thead>
                            <tr class="border-t border-b border-warm-gray-800 dark:border-warm-gray-50 bg-warm-gray-300 dark:bg-warm-gray-800 text-warm-gray-600 dark:text-warm-gray-300">
                                <th class="text-left w-52 py-2 pl-2">Subtopics</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(subtopic,i) in subtopicVotesPerUser" :key="i" class="border-b border-dashed border-warm-gray-600 text-warm-gray-500 dark:text-warm-gray-400">
                                <td class="whitespace-nowrap pl-2 py-2">{{ subtopic }}</td>
                            </tr>
                        </tbody>
                    </table>
                          
                </div>
                </div>
            </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>


</template>
<script setup>

import {ref, onMounted, watch} from 'vue';
import { useRoute } from 'vue-router';
import { getExamResultsComputedData, getCaapSubject, getLatestResponse } from '../firebase';
import { XMarkIcon, ChevronLeftIcon, ChevronRightIcon, ChevronDoubleLeftIcon } from "@heroicons/vue/24/outline";

import {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
  } from "@headlessui/vue";

const route = useRoute();

const isFirstLoad = ref(true);

const isCommentsModalOpen = ref(false);
const isAddedQuestionModalOpen = ref(false);
const isSubtopicVotesModalOpen = ref(false);

const openedSubjectInfo = ref(null);
const examResultComputedData = ref(null);
const subtopicVotes = ref([]);
const latestExamResponses = ref([]);
const daysAgo = ref(15);


const openComments = ref('');
const openAddedQuestion = ref([]);
const openAddedQuestionIndex = ref(0);

const subtopicVotesPerUser = ref([]);

const formatDate = (timestampInSeconds) => {

    const timestampInMilliseconds = timestampInSeconds * 1000;
    const date = new Date(timestampInMilliseconds);

    // Define an array to map month numbers to month names
    const months = [
    "Jan.", "Feb.", "Mar.", "Apr.", "May", "Jun.",
    "Jul.", "Aug.", "Sep.", "Oct.", "Nov.", "Dec."
    ];

    // Get the month, day, and year from the date object
    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();

    // Construct the formatted date string
    return `${month} ${day}, ${year}`;

}


const getOrdinal = (number) => {
    // Handle special cases for 11th, 12th, and 13th
    if (number % 100 >= 11 && number % 100 <= 13) {
        return number + "th";
    }

    // Handle all other cases
    switch (number % 10) {
        case 1:
            return number + "st";
        case 2:
            return number + "nd";
        case 3:
            return number + "rd";
        default:
            return number + "th";
    }
}

const closeCommentsModal = () => {
    isCommentsModalOpen.value = false;
    openComments.value = ''
}

const openCommentsModal = (comment) => {
    isCommentsModalOpen.value = true;
    openComments.value = comment
}


const closeAddedQuestionModal = () => {
    isAddedQuestionModalOpen.value = false;
    openAddedQuestion.value = []
    openAddedQuestionIndex.value = 0
}

const openAddedQuestionModal = (addedQuestion) => {
    isAddedQuestionModalOpen.value = true;
    openAddedQuestion.value = addedQuestion
}

const openSubtopicVotesModal = (_subtopicVotes) => {
    subtopicVotesPerUser.value = _subtopicVotes;
    isSubtopicVotesModalOpen.value = true;
}

const closeSubtopicVotesModal = () => {
    isSubtopicVotesModalOpen.value = false;
    subtopicVotesPerUser.value = [];
}

watch(daysAgo, async (newVal, oldVal) => {
    if (!isFirstLoad.value) {
        latestExamResponses.value = [];
        let latestRes = await getLatestResponse(route.params.subjectId, parseInt(newVal));
        latestExamResponses.value.push(...latestRes);
    }
})

onMounted(async()=>{
    isFirstLoad.value = true;
    const [examResCompData, caapSubject, latestRes] = await Promise.all([
        getExamResultsComputedData(route.params.subjectId),
        getCaapSubject(route.params.subjectId),
        getLatestResponse(route.params.subjectId, daysAgo.value)
    ])

    caapSubject.exam.subtopics.forEach((subtopic)=>{
        if (examResCompData && examResCompData.totalSubtopicVotes[subtopic]) {
            subtopicVotes.value.push({
                subtopicId: subtopic,
                vote: examResCompData.totalSubtopicVotes[subtopic]
            })
        }else{
            subtopicVotes.value.push({
                subtopicId: subtopic,
                vote: 0
            })
        }
    })

    openedSubjectInfo.value = caapSubject;
    subtopicVotes.value.sort((a,b)=> b.vote - a.vote);
    latestExamResponses.value.push(...latestRes);
    examResultComputedData.value = examResCompData || null;
    isFirstLoad.value = false;
})

</script>