import {db} from '@/firebase/config.js'
import {collection, getDocs, doc, getDoc, Timestamp, query, where, orderBy} from 'firebase/firestore'

export const getCaapSubjects = async () => {

    const querySnapshot = await getDocs(collection(db,'reviewers','caap','subjects'));
    let subjects = [];

    querySnapshot.forEach(doc => {
        subjects.push({
            id: doc.id,
            data: doc.data()
        })
    });

    return subjects;

}

export const getCaapSubject = async (subjectId) => {

    let subject = null;
    const docSnap = await getDoc(doc(db, 'reviewers','caap','subjects', subjectId))

    if (docSnap.exists()) {
        subject = docSnap.data();
    }

    return subject;

}

// export const getSubtopics = async (subjectId) => {


// }

export const getAllExamResultsComputedData = async (isReturnAsObject = false) => {

    let resObj = {};
    let resArr = [];

    const querySnapshot = await getDocs(collection(db, 'exam_results_computed_data'));

    querySnapshot.forEach((doc)=>{

        if(isReturnAsObject){
            resObj[doc.id] = doc.data();
        }else{
            resArr.push({
                id: doc.id,
                data: doc.data()
            })
        }

    })

    return isReturnAsObject ? resObj : resArr;

}

export const getExamResultsComputedData = async (subjectId) => {

    let examResultsComputedData = null;
    const docSnap = await getDoc(doc(db, 'exam_results_computed_data', subjectId))

    if (docSnap.exists()) {
        examResultsComputedData = docSnap.data();
    }

    return examResultsComputedData;

}

// get the latest or previous 15 days response
export const getLatestResponse = async (subjectId, daysAgo = 15) => {

    const res = [];

    // Get the current date and calculate the date 30 days ago
    const numOfDaysAgo = new Date(new Date());
    numOfDaysAgo.setDate(numOfDaysAgo.getDate() - daysAgo);
    console.log(numOfDaysAgo);
    // Convert dates to Firestore Timestamps
    const thirtyDaysAgoTimestamp = Timestamp.fromDate(numOfDaysAgo);
    // Construct Firestore query
    const q = query(
        collection(db, 'exam_results'),
        where('examDate', '>=', thirtyDaysAgoTimestamp),
        where('subjectId','==', subjectId),
        orderBy('examDate', 'desc')
    );
    
    // Execute the query
    await getDocs(q)
        .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            // Access document data here
            res.push({
                id: doc.id,
                data: doc.data()
            })
        });
        })
        .catch((error) => {
        console.log('Error getting documents: ', error);
        });

        return res;

}